import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { useNavigate, NavLink } from "react-router-dom";

import Button from "../common/Button";
import { logoutUser as logoutUserRequest } from "../../actions/app";

import Logo from "../../assets/Logo.png";

import "../../styles/sidebarSuperAdmin.scss";

function SidebarItem({ label, path }) {
    return (
        <NavLink
            to={path}
            className={({ isActive }) =>
                `sidebar__link ${isActive ? "sidebar__link-active" : ""}`
            }
        >
            <Button>
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width={24}
                    height={24}
                    viewBox="0 0 24 24"
                    fill="currentColor"
                >
                    <path d="M3 16h5v-2H3v2zm6.5 0h5v-2h-5v2zm6.5 0h5v-2h-5v2zM3 20h2v-2H3v2zm4 0h2v-2H7v2zm4 0h2v-2h-2v2zm4 0h2v-2h-2v2zm4 0h2v-2h-2v2zM3 12h8v-2H3v2zm10 0h8v-2h-8v2zM3 4v4h18V4H3z" />
                </svg>
                <span>&nbsp;{label}</span>
            </Button>
        </NavLink>
    );
}

SidebarItem.propTypes = {
    label: PropTypes.string,
    path: PropTypes.string,
};

const SidebarSuperAdmin = (props) => {
    const { logoutUser } = props;

    const navigate = useNavigate();

    const onLogout = () => {
        logoutUser({ userType: null, navigate });
    };

    return (
        <div className="sidebar" style={{ flexShrink: 0 }}>
            <div className="sidebar__wrapper">
                <div className="sidebar__logo-div">
                    <img className="sidebar__logo" src={Logo} alt="" />
                </div>
                <div className="sidebar__user-div" />
                <SidebarItem path="/companies" label="Manage Companies" />
                <SidebarItem path="/bots" label="Manage Bots" />
                <SidebarItem path="/superUser" label="Manage Super User" />
                <SidebarItem path="/feedback" label="Feedbacks" />
                <SidebarItem path="/user-plans" label="Saas Plans" />
                <SidebarItem
                    path="/manage-notifications"
                    label="Notifications"
                />
                <SidebarItem path="/support-msgs" label="Support Msgs" />
                <SidebarItem
                    path="/behaviour-analytics"
                    label="Behaviour Analytics"
                />
                <SidebarItem path="/restart-test" label="Restart Test" />
                <SidebarItem
                    path="/manage-skill-codes"
                    label="Manage Skill Codes"
                />
                <SidebarItem
                    path="/manage-company-users"
                    label="Manage Company Users"
                />
                <SidebarItem
                    path="/manage-workflows"
                    label="Manage Workflows"
                />
                <SidebarItem
                    path="/manage-candidates"
                    label="Manage Candidates"
                />
                <div className="sidebar__link">
                    <Button onClick={onLogout}>
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width={24}
                            height={24}
                            viewBox="0 0 24 24"
                            fill="currentColor"
                        >
                            <path d="M10.09 15.59L11.5 17l5-5-5-5-1.41 1.41L12.67 11H3v2h9.67l-2.58 2.59zM21 3H3v6h2V5h14v14H5v-4H3v6h18V3z" />
                        </svg>
                        <span>&nbsp;Log out</span>
                    </Button>
                </div>
            </div>
        </div>
    );
};

SidebarSuperAdmin.propTypes = {
    logoutUser: PropTypes.func,
};

const mapStateToProps = createStructuredSelector({});

const mapDispatchToProps = (dispatch) => ({
    logoutUser: (data) => dispatch(logoutUserRequest(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SidebarSuperAdmin);
