import React from "react";
import PropTypes from "prop-types";
import CircularProgress from "@mui/material/CircularProgress";

import styles from "./Overlay.module.css";

const Overlay = ({ show, loaderProps, children }) => {
    return (
        <div className={styles.overlayWrap}>
            {children}
            {show && (
                <div className={styles.overlay}>
                    <div className={styles.overlayBg} />
                    <div className={styles.overlayContainer}>
                        <CircularProgress {...loaderProps} />
                    </div>
                </div>
            )}
        </div>
    );
};

Overlay.propTypes = {
    show: PropTypes.bool.isRequired,
    loaderProps: PropTypes.object,
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node,
    ]),
};

export default Overlay;
