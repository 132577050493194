export const CLICK_GO_TO_NEXT_SECTION = "clickGoToNextSection";
export const CLICK_NEXT_SECTION = "clickNextSection";
export const CLICK_OPEN_RECORDER = "clickOpenRecorder";
export const CLICK_START_RECORDING = "clickStartRecording";
export const CLICK_RE_RECORD = "clickReRecord";
export const CLICK_STOP_RECORDING = "clickStopRecording";
export const CLICK_END_TEST = "clickSubmitProgress";

export const userEvents = [
    CLICK_GO_TO_NEXT_SECTION,
    CLICK_NEXT_SECTION,
    CLICK_OPEN_RECORDER,
    CLICK_START_RECORDING,
    CLICK_RE_RECORD,
    CLICK_STOP_RECORDING,
    CLICK_END_TEST,
];
