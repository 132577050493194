import React from "react";
import PropTypes from "prop-types";
import { Link, useLocation } from "react-router-dom";

import RightArrow from "../../assets/Icons/right.png";
import ChevronDownIcon from "../../assets/chevronDown.svg";

const CardContainer = ({ children }) => {
    return <div className="quickmenu__card-container">{children}</div>;
};

CardContainer.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node,
    ]),
};

const CardHeader = ({
    title,
    navigateTo,
    locationState,
    selectorId,
    countTitle,
    count,
    isMobile,
    children,
}) => {
    return (
        <div className="quickmenu__card-headingrow">
            <h3 className="quickmenu__card-heading">{title}</h3>
            {Number.isInteger(count) && (
                <p className="quickmenu__card-count">
                    {isMobile ? "Total" : `${countTitle} applicants`} : {count}
                </p>
            )}
            {navigateTo && (
                <div className="quickmenu__card-link-box">
                    <Link
                        className="quickmenu__card-link"
                        to={navigateTo}
                        state={locationState}
                        id={selectorId}
                    >
                        <h3 className="quickmenu__card-fulllist">
                            View full list{" "}
                            <img src={RightArrow} alt="right arrow" />
                        </h3>
                    </Link>
                </div>
            )}
            {children}
        </div>
    );
};

CardHeader.propTypes = {
    title: PropTypes.string.isRequired,
    navigateTo: PropTypes.string,
    locationState: PropTypes.object,
    selectorId: PropTypes.string,
    countTitle: PropTypes.string,
    count: PropTypes.number,
    isMobile: PropTypes.bool,
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node,
    ]),
};

const CardShowMore = ({ type }) => {
    const location = useLocation();

    const isAllType = type === "ALL";
    const NAVIGATE_URL = isAllType ? "/applicants" : "/finalized";
    const LOCATION_STATE_SIDEBAR = isAllType ? "applicants" : "finalised";
    const SELECTOR_ID = isAllType
        ? "quickViewFlow-selector-5"
        : "quickViewFlow-selector-6";

    return (
        <div className="quickmenu__card-showmore">
            <Link
                className="quickmenu__card-link"
                to={NAVIGATE_URL}
                state={{
                    workflowId: location.state.workflowId,
                    workflowName: location.state.caseName,
                    sidebar: LOCATION_STATE_SIDEBAR,
                }}
                id={SELECTOR_ID}
            >
                <ChevronDownIcon />
                <span>Show More</span>
            </Link>
        </div>
    );
};

CardShowMore.propTypes = {
    type: PropTypes.string.isRequired,
};

export { CardHeader, CardContainer, CardShowMore };
