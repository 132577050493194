import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { CSVLink } from "react-csv";
import { Stack } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";

import Button from "../components/common/Button";
import NoRowsOverlay from "../components/common/NoRowsDataGridOverlay";

import {
    getCompaniesList,
    getWorkflowList,
    getApplicantsLoading,
    getCandidatesList,
} from "../selectors/app";
import {
    fetchWorkflows as fetchWorkflowsRequest,
    fetchCompanies as fetchCompaniesRequest,
    getCandidatesByType as getCandidatesByTypeRequest,
} from "../actions/app";

import { getCandidateStatus } from "../utils/reports";

const WORKFLOW_STATUSES = [
    { label: "All", value: "candidates" },
    { label: "Invited", value: "added" },
    { label: "Review", value: "review" },
    { label: "Reviewed", value: "shortlisted" },
];

const DATE_OPTIONS = {
    year: "numeric",
    month: "short",
    day: "numeric",
};

const ManageCandidates = (props) => {
    const {
        allCompanies,
        workflowList,
        candidatesList,
        fetchCompanies,
        fetchWorkflows,
        fetchCandidates,
        candidatesLoading,
    } = props;

    const [selectedCompany, setSelectedCompany] = useState("");
    const [selectedWorkflow, setSelectedWorkflow] = useState("");
    const [selectedStatus, setSelectedStatus] = useState("candidates");
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    const [paginationModel, setPaginationModel] = useState({
        page: 0,
        pageSize: 25,
    });

    useEffect(() => {
        fetchCompanies();
    }, []);

    const onChangeCompany = (e) => {
        setSelectedCompany(e.target.value);
        fetchWorkflows({ companyId: e.target.value });
    };

    const onChangeWorkflow = (e) => {
        setSelectedWorkflow(e.target.value);
        fetchCandidates({
            reqData: {
                status: selectedStatus,
                id: e.target.value,
            },
        });
    };

    const onChangeStatus = (e) => {
        setSelectedStatus(e.target.value);
        fetchCandidates({
            reqData: {
                status: e.target.value,
                id: selectedWorkflow,
            },
        });
    };

    const columns = [
        {
            field: "id",
            headerName: "ID",
            width: 80,
            renderCell: (params) => {
                return <>{params.row.id}</>;
            },
        },
        {
            field: "name",
            headerName: "Name",
            flex: 1,
            renderCell: (params) => {
                return <>{params.row.name}</>;
            },
        },
        {
            field: "createdDate",
            headerName: "Date Created",
            flex: 1,
            renderCell: (params) => {
                const date = new Date(
                    params.row.createdDate
                ).toLocaleDateString("en-US", DATE_OPTIONS);
                return <>{date}</>;
            },
        },
        {
            field: "tableStatus",
            headerName: "Status",
            flex: 1,
            renderCell: (params) => {
                return (
                    <div
                        dangerouslySetInnerHTML={{
                            __html: getCandidateStatus(params.row.status),
                        }}
                    />
                );
            },
        },
    ];

    const filterCandidatesByDate = () => {
        return candidatesList.filter((candidate) => {
            const candidateDate = new Date(candidate.createdDate).getTime();
            const isAfterStartDate = startDate
                ? candidateDate >= new Date(startDate).getTime()
                : true;
            const isBeforeEndDate = endDate
                ? candidateDate <= new Date(endDate).getTime()
                : true;
            return isAfterStartDate && isBeforeEndDate;
        });
    };

    const filteredCandidates = filterCandidatesByDate();

    const companyName = allCompanies.find(
        ({ _id }) => _id === selectedCompany
    )?.name;

    const workflowName = workflowList.find(
        ({ _id }) => _id === selectedWorkflow
    )?.name;

    const statusName = WORKFLOW_STATUSES.find(
        ({ value }) => value === selectedStatus
    );

    return (
        <div
            className="mainbar"
            style={{
                display: "flex",
                flexDirection: "column",
                flex: 1,
                minHeight: "100vh",
            }}
        >
            <div
                style={{
                    padding: 40,
                    fontSize: 24,
                    fontWeight: "bold",
                    background: "#fff",
                }}
            >
                Candidates
            </div>
            <Stack
                py={2}
                direction="row"
                alignItems="center"
                flexWrap="wrap"
                gap={2}
            >
                <Stack spacing={0.5}>
                    <label>
                        <small>Company:</small>
                    </label>
                    <select value={selectedCompany} onChange={onChangeCompany}>
                        <option value="">No company</option>
                        {allCompanies.map((comp) => (
                            <option key={comp._id} value={comp._id}>
                                {comp.name}
                            </option>
                        ))}
                    </select>
                </Stack>
                <Stack spacing={0.5}>
                    <label>
                        <small>Workflow:</small>
                    </label>
                    <select
                        value={selectedWorkflow}
                        onChange={onChangeWorkflow}
                    >
                        <option value="">No workflow</option>
                        {workflowList.map((wf) => (
                            <option key={wf._id} value={wf._id}>
                                {wf.name}
                            </option>
                        ))}
                    </select>
                </Stack>
                <Stack spacing={0.5}>
                    <label>
                        <small>Status:</small>
                    </label>
                    <select value={selectedStatus} onChange={onChangeStatus}>
                        {WORKFLOW_STATUSES.map((status) => (
                            <option key={status.value} value={status.value}>
                                {status.label}
                            </option>
                        ))}
                    </select>
                </Stack>
                <Stack spacing={0.5}>
                    <label>
                        <small>Start Date:</small>
                    </label>
                    <input
                        type="date"
                        value={startDate}
                        onChange={(e) => setStartDate(e.target.value)}
                    />
                </Stack>
                <Stack spacing={0.5}>
                    <label>
                        <small>End Date:</small>
                    </label>
                    <input
                        type="date"
                        value={endDate}
                        onChange={(e) => setEndDate(e.target.value)}
                    />
                </Stack>
                {filteredCandidates?.length ? (
                    <CSVLink
                        filename={`${companyName}_${workflowName}_${statusName}_candidates.csv`}
                        data={filteredCandidates}
                        target="_blank"
                        style={{ textDecoration: "none" }}
                    >
                        <Button
                            className="btn__datagrid"
                            size="small"
                            variant="contained"
                        >
                            Download CSV
                        </Button>
                    </CSVLink>
                ) : null}
            </Stack>
            <div className="datagrid__container">
                <DataGrid
                    loading={candidatesLoading}
                    rows={filteredCandidates}
                    getRowId={(row) => row._id}
                    disableRowSelectionOnClick
                    columns={columns}
                    slots={{
                        noRowsOverlay: NoRowsOverlay,
                    }}
                    sx={{ "--DataGrid-overlayHeight": "300px" }}
                    autoHeight
                    pageSizeOptions={[5, 10, 15]}
                    paginationModel={paginationModel}
                    onPaginationModelChange={setPaginationModel}
                    className="datagrid__scroll scroll-blue"
                />
            </div>
        </div>
    );
};

ManageCandidates.propTypes = {
    workflowList: PropTypes.array,
    fetchWorkflows: PropTypes.func,
    allCompanies: PropTypes.array,
    fetchCompanies: PropTypes.func,
    candidatesList: PropTypes.array,
    fetchCandidates: PropTypes.func,
    candidatesLoading: PropTypes.bool,
};

const mapStateToProps = createStructuredSelector({
    workflowList: getWorkflowList(),
    allCompanies: getCompaniesList(),
    candidatesLoading: getApplicantsLoading(),
    candidatesList: getCandidatesList(),
});

const mapDispatchToProps = (dispatch) => ({
    fetchCompanies: () => dispatch(fetchCompaniesRequest()),
    fetchWorkflows: (data) => dispatch(fetchWorkflowsRequest(data)),
    fetchCandidates: (data) => dispatch(getCandidatesByTypeRequest(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ManageCandidates);
