import { useCallback, useEffect, useReducer } from "react";

// Define types for the state and action
const actions = {
    SET_STATUS: "SET_STATUS",
    SET_DATA: "SET_DATA",
    SET_ERROR: "SET_ERROR",
    RESET: "RESET",
};

function asyncReducer(state, { type, payload }) {
    switch (type) {
        case actions.SET_STATUS:
            return { ...state, status: payload?.status || "IDLE" };
        case actions.SET_DATA:
            return { ...state, status: "SUCCESS", data: payload?.data || "" };
        case actions.SET_ERROR:
            return { ...state, status: "ERROR", error: payload?.error || "" };
        case actions.RESET:
            return { ...state, status: "IDLE", error: "", data: "" };
        default:
            return state;
    }
}

export function useAsyncFn(
    execute,
    initialState = { status: "IDLE", error: "", data: "" }
) {
    const [state, dispatch] = useReducer(asyncReducer, initialState);

    useEffect(() => {
        return () => {
            dispatch({ type: actions.RESET });
        };
    }, []);

    // a function that execute upon call
    const submit = useCallback(
        (payload) => {
            dispatch({
                type: actions.SET_STATUS,
                payload: { status: "LOADING" },
            });
            return execute(payload)
                .then((res) => {
                    dispatch({
                        type: actions.SET_DATA,
                        payload: { data: res },
                    });
                })
                .catch((err) => {
                    dispatch({
                        type: actions.SET_ERROR,
                        payload: {
                            error:
                                err?.response?.data?.message ??
                                "something went wrong!",
                        },
                    });
                });
        },
        [execute]
    );

    return { ...state, submit };
}
