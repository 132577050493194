import {
    userEvents,
    CLICK_NEXT_SECTION,
    CLICK_GO_TO_NEXT_SECTION,
    CLICK_OPEN_RECORDER,
    CLICK_START_RECORDING,
    CLICK_STOP_RECORDING,
    CLICK_END_TEST,
    CLICK_RE_RECORD,
} from "../constants/testEvents";

function getLocaleDateTimeString(date) {
    return new Date(date).toLocaleString();
}

export function getTestSectionTimes(test) {
    return test.sectionCreatedDate.map((time, index) => ({
        label: `Section ${index + 2} start time`,
        value: getLocaleDateTimeString(time),
    }));
}

export function getTestEventStats(events) {
    if (!events || !events.length) return [];

    const filteredEvents = events.filter(({ eventType }) =>
        userEvents.some((event) => eventType?.includes(event))
    );

    let startRecordingKey = 1,
        reRecordingKey = 1;

    return filteredEvents.map(({ eventType, createdDate }) => {
        if (eventType.includes(CLICK_NEXT_SECTION)) {
            return {
                label: "Go to middle page click time",
                value: getLocaleDateTimeString(createdDate),
            };
        } else if (eventType.includes(CLICK_GO_TO_NEXT_SECTION)) {
            return {
                label: "Go to next section click time",
                value: getLocaleDateTimeString(createdDate),
            };
        } else if (eventType.includes(CLICK_OPEN_RECORDER)) {
            return {
                label: "Open recorder click time",
                value: getLocaleDateTimeString(createdDate),
            };
        } else if (eventType.includes(CLICK_START_RECORDING)) {
            const item = {
                label: `Start recording ${startRecordingKey} click time`,
                value: getLocaleDateTimeString(createdDate),
            };
            startRecordingKey += 1;
            return item;
        } else if (eventType.includes(CLICK_RE_RECORD)) {
            const item = {
                label: `Re-record ${reRecordingKey} click time`,
                value: getLocaleDateTimeString(createdDate),
            };
            reRecordingKey += 1;
            return item;
        } else if (eventType.includes(CLICK_STOP_RECORDING)) {
            return {
                label: "Stop recording click time",
                value: getLocaleDateTimeString(createdDate),
            };
        } else if (eventType.includes(CLICK_END_TEST)) {
            return {
                label: "Submit test click time",
                value: getLocaleDateTimeString(createdDate),
            };
        }
    });
}

export function getTestStats(test) {
    if (!test) return [];

    return [
        {
            label: "Test start time",
            value: getLocaleDateTimeString(test.createdDate),
        },
        {
            label: "Test end time",
            value: getLocaleDateTimeString(test.endDate),
        },
        ...getTestSectionTimes(test),
        {
            label: "Drop-off details",
            value: test?.droppedOffDetails?.status
                ? `true with ${test?.droppedOffDetails?.percentComplete}%`
                : "false",
        },
        ...getTestEventStats(test?.userEvents),
    ];
}
