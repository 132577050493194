import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

const QuickMenuRow = ({
    legendClass,
    label,
    count,
    link,
    linkState,
    iconColor,
    iconId,
    children,
}) => (
    <tr className="quickmenu__card-tr">
        <td>
            <div
                className={`quickmenu__card-legend quickmenu__card-legend-${legendClass}`}
            />
        </td>
        <td className="quickmenu__card-td">{label}</td>
        <td className="quickmenu__card-td">{count}</td>
        {link && (
            <td className="quickmenu__card-td">
                <Link to={link} state={linkState} id={iconId}>
                    View full list
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={2}
                        stroke={iconColor}
                    >
                        <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M13.5 4.5L21 12m0 0l-7.5 7.5M21 12H3"
                        />
                    </svg>
                </Link>
            </td>
        )}
        {children}
    </tr>
);

QuickMenuRow.propTypes = {
    legendClass: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    count: PropTypes.number.isRequired,
    link: PropTypes.string,
    linkState: PropTypes.object,
    iconColor: PropTypes.string,
    iconId: PropTypes.string,
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node,
    ]),
};

export default QuickMenuRow;
